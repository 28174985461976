<template>
  <div
    class="internal-header"
    :style="`background-image: url(${backgroundImage})`">
    <div class="internal-header__overlay"></div>
    <div class="internal-header__inner">
      <el-row
        type="flex"
        justify="space-between"
        align="middle">
        <el-button
          class="back-button"
          size="small"
          @click="hasHistory()
            ? $router.replace(backButton)
            : $router.push('/')"
          type="primary">
          {{ $t('global.back') }}
        </el-button>
        <div class="internal-header__section-label">
          {{ $t(`${entity}.${item.acf.tab}`) }}
        </div>
      </el-row>
      <div class="internal-header__info">
        <div
          class="internal-header__logo"
          :style="`background-image: url(${item.acf.logo});`">
        </div>
        <h1
          class="title internal-header__title"
          v-html="item.title.rendered">
        </h1>
        <div
          class="internal-header__content"
          v-html="item.content.rendered">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InternalHeader',
  props: [
    'item',
    'isLoading',
    'backgroundImage',
    'backButton',
    'entity',
  ],
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>
